var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('div',{staticClass:"mb-5",staticStyle:{"display":"flex","align-items":"center","justify-content":"center","flex-direction":"column"}},[_c('img',{attrs:{"src":_vm.logo}})]),(_vm.formReset)?_c('validation-observer',{ref:"resetForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.updateReset.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Senha","label-for":"login-password"}},[_c('validation-provider',{attrs:{"name":"Senha","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Senha"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirmação Senha","label-for":"login-password-confirmed"}},[_c('validation-provider',{attrs:{"name":"Confirmação Senha","vid":"passwordConfirmed","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password-confirmed","state":errors.length > 0 ? false : null,"type":_vm.passworConfirmedFieldType,"name":"login-password-confirmed","placeholder":"Confirmação Senha"},model:{value:(_vm.passwordConfirmed),callback:function ($$v) {_vm.passwordConfirmed=$$v},expression:"passwordConfirmed"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConfirmedToggleIcon},on:{"click":_vm.togglePasswordConfirmedVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.alterarVisible)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[(_vm.loading)?_c('b-spinner',{attrs:{"type":"grow"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.loading ? "" : "ALTERAR")+" ")],1):_vm._e(),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Retornar ao login ")],1)],1)],1)]}}],null,false,3055111468)}):_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.reset.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"E-mail","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"E-mail","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[(_vm.loading)?_c('b-spinner',{attrs:{"type":"grow"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.loading ? "" : "SOLICITAR")+" ")],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" Retornar ao login ")],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }